import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Dropdown, Menu } from 'antd';
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';

import styles from './Nav.module.scss';

import { clearAuthToken } from '../../utils/tokenHelpers';
import NavButton, { INavButton } from './NavButton';

interface NavProps {
  beforeButtons?: INavButton[];
  afterButtons?: INavButton[];
  className?: string;
}

const Nav: FC<NavProps> = ({ beforeButtons, afterButtons, className }) => {
  const location = useLocation();
  const history = useHistory();

  const handleLogoutClick = () => {
    clearAuthToken();

    history.push('/login');
  };

  return (
    <div className={classNames(styles.nav, className)}>
      {beforeButtons?.map((button) => (
        <NavButton {...button} key={button.title} />
      ))}
      <div>
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu selectedKeys={[location.pathname]}>
              <Menu.Item
                key="/logout"
                icon={<LogoutOutlined rev />}
                onClick={handleLogoutClick}
              >
                Вийти
              </Menu.Item>
            </Menu>
          }
        >
          <Button size="middle" type="primary">
            <MenuOutlined rev /> Меню
          </Button>
        </Dropdown>
      </div>
      {afterButtons?.map((button) => (
        <NavButton {...button} key={button.title} />
      ))}
    </div>
  );
};

export default Nav;
