import { FC, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { DCInfo, Loader, Nav, Studies } from '../../components';
import { saveAuthToken } from '../../utils/tokenHelpers';
import useStore from '../../hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/lib/form/Form';
import { Button, Form, Input } from 'antd';
import styles from '../../pages/LoginPage/LoginPage.module.css';
import Title from 'antd/lib/typography/Title';
import { LoadingStatus } from '../../store/types';

const SharedStudies: FC = () => {
  const locationHook = useLocation();
  const [form] = useForm();

  const { patientStore } = useStore();

  const token = new URLSearchParams(locationHook.search).get('token');
  const password = new URLSearchParams(locationHook.search).get('password');

  useEffect(() => {
    (async () => {
      if (!token || !password || password!?.length <= 2) {
        return;
      }
      saveAuthToken(token, '');

      await patientStore.getStudies();
    })();
  }, []);

  const onFinish = async (values: any) => {
    const updatePasswordInURL = (newPassword: string) => {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('password', newPassword);
      const newUrl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }?${urlParams.toString()}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);
    };

    updatePasswordInURL(values.password);

    window.location.reload();
  };

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {patientStore.getPatientsLoadingStatus === LoadingStatus.LOADING ? (
        <Loader />
      ) : !patientStore.patients.length ? (
        <Form
          form={form}
          name="password"
          className={styles.form}
          style={{ maxWidth: 500 }}
          initialValues={{
            password: '',
          }}
          onFinish={onFinish}
        >
          <Title level={4} className={styles.title}>
            Введіть пароль
          </Title>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button className={styles.submit} type="primary" htmlType="submit">
              Увійти
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <>
          <DCInfo />
          <Nav />

          <Studies sharing={false} />
        </>
      )}
    </>
  );
};

export default observer(SharedStudies);
