import { ReactNode } from 'react';
import { Button, Space, Table } from 'antd';
import {
  IPatient,
  IPatientStudy,
  IStudyDownload,
} from '../../../store/patient';
import FileActionButtons from './FileActionButtons';
import IsoFileActionButtons from './IsoFileActionButtons';
import { ExportOutlined } from '@ant-design/icons';
import styles from '../Patient.module.scss';

const downloadsColumns = (
  study: IPatientStudy,
  handleFileShow: (studyUUID: string, fileUUID: string) => void,
  handleFileDownload: (
    studyName: string,
    studyUUID: string,
    file: IStudyDownload
  ) => void,
  handleFilePrepare: (studyUUID: string) => void,
  checkStudyImage: (study: IPatientStudy) => Promise<void>,
  downloadFileLoading: string | null
) => {
  const donwloadActions = (download: IStudyDownload) => {
    const isFilm = download.type === 'Плівка';
    const isIso = download.type === 'Образ диску';

    if (isIso) {
      if (download.isoStatus === undefined) {
        return (
          <div
            className={styles.isoPrepare}
            style={{
              marginLeft: 15,
            }}
          >
            <Button size={'small'} onClick={() => checkStudyImage(study)}>
              Завантажити образ диску
            </Button>
          </div>
        );
      } else {
        return (
          <IsoFileActionButtons
            message={download.message}
            isoStatus={download.isoStatus}
            onFileDownload={() =>
              handleFileDownload(study.services, study.studyUUID, download)
            }
            onFilePrepare={() => handleFilePrepare(study.studyUUID)}
            downloadFileLoading={download.fileUUID === downloadFileLoading}
          />
        );
      }
    }

    return download.fileUUID ? (
      <FileActionButtons
        onFileDownload={() =>
          handleFileDownload(study.services, study.studyUUID, download)
        }
        onFileShow={
          isFilm
            ? undefined
            : () => handleFileShow(study.studyUUID, download.fileUUID)
        }
        downloadFileLoading={download.fileUUID === downloadFileLoading}
      />
    ) : undefined;
  };
  // Define columns for the table
  return [
    {
      dataIndex: 'downloads',
      key: 'downloads',
      responsive: ['xs'],
      render: (_: any, download: IStudyDownload) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <div
              style={{
                display: !!download.type ? 'block' : 'none',
                marginTop: 1,
              }}
            >
              {download.type}
            </div>
            <div style={{ display: !download.services ? 'none' : 'block' }}>
              {download.services}
            </div>
            <div>{donwloadActions(download)}</div>
          </div>
        );
      },
    },
    {
      dataIndex: 'downloads',
      key: 'downloads',
      responsive: ['sm'],
      render: (_: any, download: IStudyDownload) => {
        return (
          <div className={styles.downloads}>
            <div style={{ display: !!download.type ? 'block' : 'none' }}>
              {download.type}
            </div>
            <div style={{ display: !download.services ? 'none' : 'block' }}>
              {download.services}
            </div>
          </div>
        );
      },
    },
    {
      dataIndex: 'downloads',
      key: 'downloads',
      responsive: ['md'],
      width: '50%',
      render: (_: any, download: IStudyDownload) => {
        return (
          <div>
            <div>{download.type}</div>
            <div style={{ paddingLeft: 20 }}>{download.services}</div>
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      responsive: ['sm'],
      render: (download: IStudyDownload) => {
        return donwloadActions(download);
      },
    },
  ];
};

const StudyRows = (
  sharing: boolean,
  studyShareClick: (studyUUID: string) => void,
  handleFileShow: (studyUUID: string, fileUUID: string) => void,
  handleFileDownload: (
    studyName: string,
    studyUUID: string,
    file: IStudyDownload
  ) => void,
  downloadFileLoading: string | null,
  checkStudyImage: (study: IPatientStudy) => Promise<void>,
  handleFilePrepare: (studyUUID: string) => void
) => {
  const array = [
    {
      fileUUID: 'a46f06d8-16da-4139-892f-da9fc6de81e1',
      isoStatus: undefined,
      message: undefined,
      services: 'МРТ 1',
      storage: null,
      type: 'Висновок лікаря рентгенолога',
    },
    {
      fileUUID: 'a46f06d8-16da-4139-892f-da9fc6de81e2',
      isoStatus: undefined,
      message: undefined,
      services: 'МРТ 2',
      storage: null,
      type: 'Висновок лікаря рентгенолога',
    },
    {
      fileUUID: 'a46f06d8-16da-4139-892f-da9fc6de81e3',
      isoStatus: undefined,
      message: undefined,
      services: 'МРТ 3',
      storage: null,
      type: 'Висновок лікаря рентгенолога',
    },
  ];
  const createTableData = (array: any) => {
    // Iterate over the dataSource array to find unique types
    // Group objects by type
    const groupedData: Record<string, IStudyDownload[]> = array.reduce(
      (acc: any, obj: any) => {
        // Extract the type from the object
        const { type, ...rest } = obj;
        // Check if the type already exists in the accumulator
        if (type in acc) {
          // If the type exists, push the object to its array
          acc[type].push(rest);
        } else {
          // If the type doesn't exist, create a new array with the object
          acc[type] = [rest];
        }
        return acc;
      },
      {}
    );

    const tableData = Object.entries(groupedData).map(([type, items]) =>
      items.length > 1
        ? {
            key: type,
            type,
            children:
              items.length > 1
                ? items.map((item) => ({
                    key: item.fileUUID,
                    ...item,
                  }))
                : undefined,
          }
        : {
            key: type,
            type,
            fileUUID: items[0].fileUUID,
            storage: items[0].storage,
            isoStatus: items[0].isoStatus,
            message: items[0].message,
            services: items[0].services,
          }
    );

    return tableData;
  };

  return (row: IPatient): ReactNode => {
    return (
      <Table
        columns={[
          {
            dataIndex: 'date',
            key: 'date',
            width: 90,
          },
          {
            dataIndex: 'services',
            key: 'services',
            render: (text: string, study: IPatientStudy) =>
              sharing ? (
                <Space>
                  <span>{text}</span>
                  <Button
                    icon={<ExportOutlined rev />}
                    onClick={(e) => {
                      e.stopPropagation();
                      studyShareClick(study.studyUUID);
                    }}
                  />
                </Space>
              ) : (
                <span>{text}</span>
              ),
          },
        ]}
        size="small"
        defaultExpandedRowKeys={[row.visits[0]?.studyUUID]}
        expandRowByClick
        locale={{ emptyText: 'Пусто' }}
        showHeader={false}
        dataSource={row.visits.map((s) => ({ ...s, key: s.studyUUID }))}
        expandedRowRender={(study: IPatientStudy): ReactNode => (
          <Table
            //@ts-ignore
            columns={downloadsColumns(
              study,
              handleFileShow,
              handleFileDownload,
              handleFilePrepare,
              checkStudyImage,
              downloadFileLoading
            )}
            size="small"
            showHeader={false}
            //@ts-ignore
            dataSource={createTableData(
              study.downloads.map((s) => ({ ...s, key: s.fileUUID }))
            )}
            pagination={false}
          />
        )}
        pagination={false}
      />
    );
  };
};

export default StudyRows;
